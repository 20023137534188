import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  GET_DO_I_QUALIFY_QUESTIONS_URL
,SEND_Me_Application,Check_Now } from './api.urls';
import { DoIQualifyFormModel } from '../models/DoIQualifyForm';

@Injectable({
  providedIn: 'root'
})
export class DoIQualifyService {
  constructor(private _http: HttpClient) {

  }

  GetQuestions(data) : Observable<Comment[]> {
    let url = GET_DO_I_QUALIFY_QUESTIONS_URL+"/"+data['carrier_id']+'/'+data['product_id'];

    return this._http.get(url) 
      .pipe(map((result: any) => result));
  }

  SaveResponse(data) {

  }
  SaveCheckNow(data: DoIQualifyFormModel):Observable<Comment[]> {

    return this._http.post(Check_Now,data) 
      .pipe(map((result: any) => result));
  } 
  SaveSendMeApplication(data: DoIQualifyFormModel):Observable<Comment[]> {

    return this._http.post(SEND_Me_Application,data) 
      .pipe(map((result: any) => result));
  }
}