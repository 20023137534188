import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { 
  HomeComponent,
  UnderwritingComponent,
  AboutusComponent,
  ImmediateAnnuitiesComponent,
  WhoWeAreComponent,
  DoIQualifyComponent,
  SpaFormComponent,
  ResultComponent,
  ApplyComponent,
  SuccessComponent,
} from './pages';

export const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    component: HomeComponent,
  },
  // {
  //   path: 'underwriting',
  //   component: UnderwritingComponent,
  // },
  {
    path: 'about-us',
    component: AboutusComponent,
  },
  {
    path: 'immediate-annuities',
    component: ImmediateAnnuitiesComponent,
  },
  {
    path: 'who-we-are',
    component: WhoWeAreComponent,
  },
  {
    path: 'do-i-qualify',
    component: DoIQualifyComponent,
  },
  {
    path: 'get-quote',
    component: SpaFormComponent,
  },
  {
    path: 'result',
    component: ResultComponent,
  },
  {
    path: 'apply',
    component: ApplyComponent,
  },
  {
    path: 'thank-you',
    component: SuccessComponent,
  },
  
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
